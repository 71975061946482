@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700&display=swap")

::-webkit-scrollbar
  background-color: black
  width: 10px

::-webkit-scrollbar-track
  background-color: #555
  margin-block: 10px
  border-radius: 100vw

::-webkit-scrollbar-thumb
  background-color: white
  border-radius: 100vw

::-webkit-scrollbar-thumb::hover
  opacity: .5

.App
  /*text-align: center;*/
  /*font-weight: normal;*/
  margin: 0
  padding: 0
  box-sizing: border-box
  outline: none
  font-family: 'Poppins', sans-serif

  /*background-image: url("./images/wallpaper.jfif");*/
  /*background-image: linear-gradient(.25turn, white, blue);*/

  display: flex
  /*justify-content: center;*/
  align-items: center
  min-height: 100vh
  background-color: black
  flex-direction: column

.header
  height: 100px
  width: 100%

.container
  margin-bottom: 100px
  width: 65%
  padding: 40px
  background-color: #151515
  border-radius: 5px

  & h1
    color: #999
    margin-bottom: 50px
    font-size: 2.5rem
    margin-top: 0

  & p
    color: white
    font-size: 16px

.input-field
  position: relative
  height: 500px
  width: 100%

  & textarea
    position: absolute
    background: transparent
    box-shadow: none
    border: 1px white solid
    border-radius: 5px
    font-size: 16px
    color: #fff
    width: 100%
    margin-top: 30px
    height: 450px

  & label
    padding: 30px 5px
    position: absolute
    top: 0
    left: 0
    color: #555
    pointer-events: none
    display: block

    transition: .5s
    -webkit-transition: .5s
    -moz-transition: .5s
    -ms-transition: .5s
    -o-transition: .5s

    border-radius: 5px

.input-field textarea:focus + label,
.input-field textarea:valid + label
  -webkit-transform: translateY(-50px)
  -moz-transform: stranslateY(-50px)
  -ms-transform: translateY(-50px)
  -o-transform: translateY(-50px)
  transform: translateY(-50px)

  font-size: 14px
  color: #fff
  background: #ff006a
  padding: 6px 10px

.input-field span
  position: absolute
  bottom: 0
  right: 0
  display: block
  background-color: #555
  width: 100%
  height: 2px

  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: #00b0ff

    -webkit-transform: scaleX(0)
    -moz-transform: scaleX(0)
    -ms-transform: scaleX(0)
    -o-transform: scaleX(0)
    transform: scaleX(0)

    -webkit-transform-origin: right
    -moz-transform-origin: right
    -ms-transform-origin: right
    -o-transform-origin: right
    transform-origin: right

    -webkit-transition: transform .5s ease-in-out
    -moz-transition: transform .5s ease-in-out
    -ms-transition: transform .5s ease-in-out
    -o-transition: transform .5s ease-in-out
    transition: transform 0.5s ease-in-out

.input-field textarea:focus ~ span:before,
.input-field textarea:valid ~ span:before
  -webkit-transform: scaleX(1)
  -moz-transform: scaleX(1)
  -ms-transform: scaleX(1)
  -o-transform: scaleX(1)
  transform: scaleX(1)

  -webkit-transform-origin: left
  -moz-transform-origin: left
  -ms-transform-origin: left
  -o-transform-origin: left
  transform-origin: left

  -webkit-transition: transform .5s ease-in-out
  -moz-transition: transform .5s ease-in-out
  -ms-transition: transform .5s ease-in-out
  -o-transition: transform .5s ease-in-out
  transition: transform 0.5s ease-in-out

.btn
  border: none
  box-shadow: none
  padding: 10px 25px
  background-color: #333
  color: #fff
  font-size: 16px
  cursor: pointer
  border-radius: 5px
  margin-top: 20px
  font-family: 'Poppins', sans-serif
  transition: all .3s

  &-paste:hover
    background-color: #00bcd4

  &-paste:active
    background-color: #2CC8DC

  &-select:hover
    background-color: #00bcd4

  &-select:active
    background-color: #2CC8DC

  &-clear:hover
    background-color: #FF1313

  &-clear:active
    background-color: #FF3D3D

  &-check:hover
    background-color: #3CD604

  &-check:active
    background-color: #22BB00

  &-paste
    margin-right: 20px

.bottom-row
  display: flex
  justify-content: space-between
  margin-top: 20px

  & button
    border-radius: 20px

/* Results CSS! */
.bottom-parent
  width: 100%
  height: 100%
  margin: 0
  padding: 0
  box-sizing: border-box
  margin-bottom: 100px

  display: flex
  justify-content: center
  /*align-items: center;*/
  flex-direction: row

.container-other
  width: 50%
  padding: 40px
  background-color: #151515
  border-radius: 5px

  & h1
    color: #999
    margin-bottom: 50px
    font-size: 2.5rem
    margin-top: 0

  & p
    color: white
    font-size: 16px

  .right-column, &
    height: 750px

    overflow-y: auto
    overflow-x: hidden

.right-column
  height: 830px
  width: 25%
  padding: 0 60px 0 60px

  display: block
  flex-direction: column
  justify-content: center
  align-items: center
  background-color: black

  overflow-y: auto

.side-container
  display: flex
  flex-direction: column
  align-content: center
  margin-bottom: 30px
  width: 100%
  /*padding: 20px;*/
  background-color: #232323
  border-radius: 50px

  & h2
    color: #999
    font-size: 22px
    margin-left: 10px
  /*margin-bottom: 50px;*/

  & p
    color: white
    font-size: 18px
    padding: 10px 20px 10px 30px

  &-header
    height: 27px
    display: flex
    flex-direction: row
    padding: 25px
    align-items: center

.importantButtons
  width: 80%
  display: flex
  flex-direction: row
  /*flex-wrap: wrap;*/
  align-items: center

.checkbox-list
  margin-left: 20px
  display: flex
  flex-direction: column
  color: white
  font-size: 14px

.checkbox-item
  padding: 2px
  display: flex
  flex-direction: row

  & label
    cursor: pointer

  & input[type="checkbox"]
    cursor: pointer

.btn-select
  margin-left: 30px

.red-number, .red-line
  background-color: #FF1313

.green-number, .green-line
  background-color: #3CD604

.yellow-number, .yellow-line
  background-color: #D6D624

.red-color
  color: #FF1313

.green-color
  color: #3CD604

.yellow-color
  color: yellow

.under-line
  display: block
  align-self: center
  width: 90%
  height: 2px

.red-number, .green-number, .yellow-number
  color: white
  padding: 1px 8px
  border-radius: 50%
